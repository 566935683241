<template>
    <div>
        <Loading :center="true" v-show="loading" />
        <b-tabs>
            <b-tab title="Configuração">
                <div class="main">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                            <InputText title="Marca" field="name" :required="true" :maxLength="20" :markFormDirty="false"
                                v-model="config.name" />
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                            <InputText title="Cor" field="name" :required="false" :maxLength="20" :markFormDirty="false"
                                v-model="config.primaryColor" />
                            <Color titulo="Cor do Botão" v-model="config.primaryColor" />
                        </b-col>
                    </b-row>
                </div>
                <div>
                    <Button _key="btnSaveConfig" type="success" title="Salvar" classIcon="fas fa-save" size="medium"
                        :clicked="save" />
                </div>
            </b-tab>
            <b-tab title="Logo">
                <ImageUpload title="Carregar Log..." container="nixloc-signature-logo" :width="120" :height="60"
                    :urlImage="config.urlLogo" accepted=".jpg" urlPost="/api/v1/adm/file-upload/upload"
                    urlRemove="/api/v1/adm/file-upload/delete" v-model="config.urlLogo" :onLoad="save" />
                <br>
            </b-tab>
        </b-tabs>

    </div>
</template>
<script>

import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Color from "@nixweb/nixloc-ui/src/component/forms/Color";
import ImageUpload from "@nixweb/nixloc-ui/src/component/forms/ImageUpload";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "SignatureConfig",
    components: {
        InputText, ImageUpload, Button, Loading, Color
    },
    data() {
        return {
            created: false,
            loading: true,
            urlCreate: "/api/v1/signature-config/create",
            urlUpdate: "/api/v1/signature-config/update",
            urlGetById: "/api/v1/signature-config/get-by-id",
            config: {
                id: "",
                name: "",
                primaryColor: "",
                urlLogo: "",
            },
        }
    },
    mounted() {
        this.getById();
        this.addLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        ...mapMutations("generic", ["addLoading", "removeLoading"]),
        getById() {
            this.loading = true;
            let params = {
                url: this.urlGetById,
                obj: {},
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    if (response.content == null) {
                        this.created = false;
                    } else {
                        this.config = response.content;
                    }
                    this.loading = false;
                }
            });
        },
        save() {
            if (this.created) {
                let params = { url: this.urlUpdate, obj: this.config };
                this.putApi(params).then((response) => {
                    if (response.success) {
                        this.removeLoading(["btnSaveConfig"]);
                    }
                });
            } else {
                let params = { url: this.urlCreate, obj: this.config };
                this.postApi(params).then((response) => {
                    if (response.success) {
                        this.config = response.content;
                        this.removeLoading(["btnSaveConfig"]);
                    }
                });
            }
        },
    },
}


</script>
<style scoped>
.main {
    margin-top: 25px;
}
</style>